<template lang="pug">
  div
    v-nav-tabs(
      v-if="!isMobile"
      :options="tabs")
    v-mobile-tabs(
      v-else
      :options="tabs")

</template>

<script>
import { mapGetters } from 'vuex'
import adaptationMixin from '@/mixins/adaptation.mixin'
import permissions from '@/util/permissions'

export default {
  mixins: [adaptationMixin],
  computed: {
    ...mapGetters(['user']),
    tabs () {
      return this.makeTabs()
    }
  },
  methods: {
    makeTabs () {
      return [
        {
          title: 'layouts.sidebar.ingredients',
          path: '/farm/storage/ingredients',
          disabled: !this.user.user.permissions.some(permission => permission.name === permissions.read_ingredient)
        },
        {
          title: 'layouts.sidebar.supplies',
          path: '/farm/storage/supplies',
          disabled: !this.user.user.permissions.some(permission => permission.name === permissions.read_accounting)
        },
        {
          title: 'layouts.sidebar.expense',
          path: '/farm/storage/expense',
          disabled: !this.user.user.permissions.some(permission => permission.name === permissions.read_accounting)
        },
        {
          title: 'layouts.sidebar.suppliers',
          path: '/farm/storage/suppliers',
          disabled: !this.user.user.permissions.some(permission => permission.name === permissions.read_provider)
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
