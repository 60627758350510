<template lang="pug">
  div
    .sidebar-layout__toolbar
      button.btn.btn--brand(
        v-permission-hide="permissions.read_ingredient"
        :class="{ 'btn--yellow': hasIngredientsFilters, 'btn--disabled': false }"
        @click="modals.showFilters = true")
        font-awesome-icon.mr-1(
          size="sm"
          icon="filter")
        span {{ 'actions.add_filter' | translate }}
      v-dialog(
        max-width="450"
        v-model="modals.showFilters")
        filters-modal(
          @close="modals.showFilters = false")
      button.btn.btn--brand.ml-2(
        v-if="!isMobile"
        v-permission-hide="permissions.create_ingredient"
        @click="modals.showCreateNew = true")
        font-awesome-icon.mr-1(
          size="sm"
          icon="plus")
        span {{ 'actions.add_ingredient' | translate }}
      v-dialog(
        max-width="348"
        v-model="modals.showCreateNew")
        create-new-modal(
          @close="modals.showCreateNew = false")
    navigation
    //- v-mobile-tabs(:options="tabs")
    //- FOR DESKTOP AND TABLETS
    template(v-if="!isMobile")
      ingredients-table
    //- FOR MOBILE ONLY
    template(v-if="isMobile")
      ingredients-table-mobile
</template>

<script>
import { mapGetters } from 'vuex'

import adaptationMixin from '@/mixins/adaptation.mixin'

import Navigation from '../common/Navigation'

import FiltersModal from './modals/FiltersModal'
import CreateNewModal from './modals/CreateNewModal'
import permissions from '@/util/permissions'

const IngredientsTable = () => import('./IngredientsTable')
const IngredientsTableMobile = () => import('./IngredientsTableMobile')

export default {
  name: 'StorageIngredients',

  mixins: [adaptationMixin],

  components: {
    Navigation,
    IngredientsTable,
    IngredientsTableMobile,
    FiltersModal,
    CreateNewModal
  },

  data: () => ({
    modals: {
      showFilters: false,
      showCreateNew: false
    },
    tabs: [
      {
        title: 'Інгредієнти',
        path: '/farm/storage/ingredients'
      },
      {
        title: 'Прийом',
        path: '/farm/storage/supplies'
      },
      {
        title: 'Списання',
        path: '/farm/storage/expense'
      },
      {
        title: 'Постачальники',
        path: '/farm/storage/suppliers'
      }
    ],
    permissions: permissions
  }),

  computed: {
    ...mapGetters([
      'hasIngredientsFilters'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
