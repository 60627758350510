<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.ingredient.create.title' | translate }}
    v-card-content
      v-text-field.mb-3(
        :label="$t('base.name')"
        v-model="form.name")
      v-text-field.mb-3(
        :label="$t('base.code_1c')"
        v-model="form.code_1c")
      v-text-field.mb-3(
        :label="$t('base.valve_value')"
        v-model="form.valve_value")
      v-switch(
        :label="$t('base.autoload')"
        v-model="form.autoload")
    v-card-actions
      v-btn(
        :disabled="!isNameValid"
        :loading="loading"
        @click="onCreate") {{ 'actions.create' | translate }}

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CreateNewModal',

  data: () => ({
    form: {
      name: '',
      code_1c: '',
      autoload: false,
      valve_value: 3
    },
    loading: false
  }),

  computed: {
    isNameValid () {
      return !!this.form.name
    }
  },

  methods: {
    ...mapActions(['addIngredient']),
    async onCreate () {
      this.loading = true
      const payload = {
        ...this.form
      }
      const { data, error } = await this.addIngredient(payload)
      this.loading = false
      if (error) {
        for (const key in data.errors) {
          this.$toasted.show(data.errors[key], {
            position: 'bottom-center',
            duration: 5000
          })
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
