<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'base.filter' | translate }}
    v-card-content
      .cols-x
        .col-x-2.mb-3
          v-text-field(
            :label="$t('modals.ingredient.filter.per_day_from')"
            v-model="form.per_day_from")
        .col-x-2.mb-3
          v-text-field(
            :label="$t('modals.ingredient.filter.per_day_to')"
            v-model="form.per_day_to")

      .cols-x
        .col-x-2.mb-3
          v-text-field(
            :label="$t('base.cost_from')"
            v-model="form.cost_from")
        .col-x-2.mb-3
          v-text-field(
            :label="$t('base.cost_to')"
            v-model="form.cost_to")

      .cols-x
        .col-x-2.mb-3
          v-text-field(
            :label="$t('modals.ingredient.filter.remainder_day_from')"
            v-model="form.remainder_day_from")
        .col-x-2.mb-3
          v-text-field(
            :label="$t('modals.ingredient.filter.remainder_day_to')"
            v-model="form.remainder_day_to")

      .cols-x
        .col-x-2.mb-3.remove-margin-above-mobile
          v-text-field(
            :label="$t('modals.ingredient.filter.remainder_weight_from')"
            v-model="form.remainder_weight_from")
        .col-x-2
          v-text-field(
            :label="$t('modals.ingredient.filter.remainder_weight_to')"
            v-model="form.remainder_weight_to")

    v-card-actions
      v-btn.mr-2(
        :loading="loading"
        @click="onApply") {{ 'actions.apply' | translate }}
      v-btn(
        text
        @click="onReset") {{ 'actions.clear' | translate }}

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FiltersModal',

  mounted () {
    const filters = this.$store.getters.ingredientsFilters
    for (const key in filters) {
      this.form[key] = filters[key]
    }
  },

  data: () => ({
    form: {
      per_day_from: null,
      per_day_to: null,
      cost_from: null,
      cost_to: null,
      remainder_day_from: null,
      remainder_day_to: null,
      remainder_weight_from: null,
      remainder_weight_to: null,
      per_day_value: 1
    },
    loading: false
  }),

  methods: {
    ...mapActions(['applyIngredientsFilters']),

    async onApply () {
      this.loading = true
      const filters = {}
      for (const key in this.form) {
        if (this.form[key] !== null) {
          filters[key] = this.form[key]
        }
      }
      await this.applyIngredientsFilters(filters)
      this.loading = false
      this.$emit('close')
    },

    onReset () {
      for (const key in this.form) {
        this.form[key] = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
